<template>
  <div class="d-flex root-div">
    <!--begin::Form-->
    <v-card class="login-div">
      <div class="mt-5 d-flex justify-content-center">
        <img :src="assets.logoDark" :height="30" />
      </div>
      <p class="h2 m-5 font-bold" align="center">{{ $t('admin_login') }}</p>
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <b-form-group :label="$t('email')">
          <b-form-input
            class="form-control form-control-solid p-2"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
          />
        </b-form-group>
        <b-form-group :label="$t('password')">
          <b-input-group>
            <b-form-input
              class="form-control form-control-solid p-2"
              :type="showPassword ? 'text' : 'password'"
              v-model="$v.form.password.$model"
              :state="validateState('password')"
            />
            <template v-slot:append>
              <v-btn icon @click="showPassword = !showPassword">
                <v-icon>{{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
              </v-btn>
            </template>
          </b-input-group>
        </b-form-group>

        <!--begin::Action-->
        <div class="w-100" align="end">
          <button class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3">
            {{ $t('login') }}
          </button>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </v-card>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/sass/pages/login.scss";
</style>

<script>
import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";
import { firebaseAuth } from '../../main';
import { showLoading } from '../../functions';
import { signInWithEmailAndPassword } from 'firebase/auth';

import logoDark from '@/assets/image/logo_dark.png';

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      // Remove this dummy login info
      form: {
        email: '',
        password: ''
      },
      assets: {
        logoDark
      },
      showPassword: false
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(6)
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;

      const loader = showLoading(this);
      signInWithEmailAndPassword(firebaseAuth, email, password)
      .then(result => {
        loader.hide();
        if (result.user.uid === this.ADMIN_UID) {
          this.$router.push('/');
        } else {
          this.$toast.error(this.$t('you_are_not_admin'));
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error('Unexpected error occurs - ' + error.message);
      });
    }
  }
};
</script>